'use client'
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'next/navigation'

import styled from 'styled-components'
import { AddressInput } from './address'
import { DistanceSelect } from './distance'
import { postcodeValidation } from '../../utils/formValidation'
import { useFilterState } from '../../filterContext'

const FieldContainer = styled.div``

export const DistanceSearch = ({
  addressLabel = '',
  addressId = 'postalcode',
  addressName = 'postalcode',
  distanceId = 'distance',
  distanceName = 'distance',
}) => {
  const { handleDistancePostalcodeChange } = useFilterState()
  const searchParams = useSearchParams()
  const query = searchParams.get('query')

  const [address, setAddress] = useState('')
  const [addressValid, setAddressValid] = useState()
  const [distance, setDistance] = useState(0)

  useEffect(() => {
    const queryDistance = searchParams.get('distance')
    const queryPostalcode = searchParams.get('postal_code')

    // Set distance and address from query params
    setDistance(queryDistance || distance)
    setAddress(queryPostalcode || address)
    setAddressValid(
      queryPostalcode ? postcodeValidation(queryPostalcode) : true
    )
  }, [])

  const handleFiltering = (inAddress, inAddressValid, inDistance) => {
    if (inAddress && inAddressValid) {
      const newDistanceAddress = {
        distance: inDistance || 0,
        postalCode: inAddress,
      }
      handleDistancePostalcodeChange(newDistanceAddress)
    }
  }

  const handleAddressChange = (e) => {
    e.preventDefault()
    setAddress(e.target.value)
  }
  const handleAddressBlur = (e) => {
    e.preventDefault()
    const { value } = e.target
    const isValid = postcodeValidation(value)
    setAddressValid(isValid)

    if (isValid) {
      handleFiltering(value, isValid, distance)
    }
  }
  const handleDistanceChange = (e) => {
    e.preventDefault()
    const { value } = e.target
    setDistance(value)
    handleFiltering(address, addressValid, value)
  }

  return (
    <FieldContainer>
      <AddressInput
        value={address}
        label={addressLabel}
        id={addressId}
        name={addressName}
        handleChange={handleAddressChange}
        handleBlur={handleAddressBlur}
        isValid={addressValid}
      />
      <DistanceSelect
        value={distance}
        id={distanceId}
        name={distanceName}
        handleChange={handleDistanceChange}
      />
    </FieldContainer>
  )
}
