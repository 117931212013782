import React from 'react'
import styled from 'styled-components'
import { Select, MenuItem } from '@mui/material'

const FieldContainer = styled.div`
  width: 82px;
  height: 37px;
  display: inline-block;
  vertical-align: middle;
  div.MuiInputBase-root {
    padding: 10px;
    background-color: #2e842a;
    color: #fff;
    border: 0;

    #distance {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      overflow: visible;
    }

    svg {
      stroke: #fff;
      fill: #fff;
      width: 18px;
    }
  }
`

export const DistanceSelect = ({
  handleChange,
  id = 'distance',
  value,
  name = 'distance',
}) => {
  const options = [
    {
      value: 1,
      name: '1 km',
    },
    {
      value: 2,
      name: '2 km',
    },
    {
      value: 5,
      name: '5 km',
    },
    {
      value: 10,
      name: '10 km',
    },
    {
      value: 0,
      name: '+10 km',
    },
  ]
  const pcs = options?.map((item) => {
    return (
      <MenuItem value={item.value} key={item.value}>
        {item.name}
      </MenuItem>
    )
  })
  return (
    <FieldContainer>
      <Select
        id={id}
        onChange={handleChange}
        value={value}
        label={''}
        name={name}
        multiple={false}
      >
        {pcs}
      </Select>
    </FieldContainer>
  )
}
