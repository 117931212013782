import React from 'react'
import styled from 'styled-components'
import { Input, InputLabel, FormHelperText } from '@mui/material'

import SvgElement from '../../elements/svg'

const FieldContainer = styled.div`
  max-width: 100%;
  width: 220px;
  margin-right: 2px;
  position: relative;
  height: 37px;
  display: inline-block;
  vertical-align: middle;
  div.MuiInputBase-root {
    max-height: 38px;
    border: 2px solid #d9d9d9;
    border-radius: 1px;
    background-color: #fdfdfd;
    padding-left: 40px;
  }
  .searchicon {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
  }
`

export const AddressInput = ({
  label = 'Input label',
  placeholder = 'Postcode',
  handleChange,
  id,
  name,
  handleBlur,
  value,
  isValid,
}) => {
  const inputProps = {
    id: id,
    placeholder: placeholder,
    name: name,
  }
  let error = value && !isValid && 'Het gegeven adres is niet geldig'
  return (
    <FieldContainer>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <SvgElement
        className="searchicon"
        variant="search2"
        width={14}
        height={14}
        color={'#1D1D1B'}
      />
      <Input
        id={id}
        inputProps={{ ...inputProps }}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        error={error && true}
        onBlur={handleBlur}
        value={value}
      />
      {error && (
        <FormHelperText error={error && true} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </FieldContainer>
  )
}
