import { FilterWrapper } from './views/styles'
import close from './assets/close.png'
import ButtonElement from '@/core/elements/buttons'
import { ImageBuilderFrontend } from '@/core/elements/imageBuilder'
import SvgElement from '@/core/elements/svg'
import TypographyElement from '@/core/elements/typography'
import FilterSubList, {
  AttributeFilterList,
  FilterDistance,
  FilterLinkList,
} from '@/core/compositions/filterSublist'

import { white } from '@/core/colors'

const FilterItem = ({ text, handleRemove }) => {
    return (
      <FilterWrapper>
        <TypographyElement
          variant="p"
          color={white}
          sx={{ paddingBottom: 0, textTransform: 'capitalize' }}
        >
          {text}
        </TypographyElement>
        <div onClick={handleRemove}>
          <SvgElement variant="close" />
        </div>
      </FilterWrapper>
    )
  }
  
  // Sidebar
const FilterSidebar = ({
    filterSubList,
    mainFilters,
    attributeFilters,
    additionalFilters,
    category,
    filters,
    handleFiltersChange,
    handleFilters,
    selectedAttributes,
    selectedAdditionals,
    handleAttributeChange,
    handleAdditionalChange,
    handleDistancePostalcodeChange,
    removeFilters,
    setMobileOpen,
    resultsPerCategory,
    resultsPerSport,
    isLocation,
    distancePostalcode,
    handleCategoryChange,
  }) => {
    const getAllFilters = () => {
      if (
        !category &&
        !filters?.length &&
        !selectedAttributes?.length &&
        !selectedAdditionals?.length &&
        !distancePostalcode
      ) {
        return null
      }
      return (
        <div className="selectedFilters">
          {distancePostalcode && (
            <FilterItem
              key="filter-category-postcalcode"
              text={distancePostalcode.postalCode}
              handleRemove={() => {
                handleDistancePostalcodeChange({})
              }}
            />
          )}
          {category?.text && (
            <FilterItem
              key="filter-category-text"
              text={category?.text}
              handleRemove={() => {
                handleCategoryChange('')
              }}
            />
          )}
          {filters?.map((sport, i) => {
            return (
              <FilterItem
                key={`filter-sports-${i}`}
                text={sport?.text}
                handleRemove={() => {
                  const removeFilterState = filters.filter(
                    (item) => item != sport
                  )
                  handleFiltersChange(removeFilterState)
                }}
              />
            )
          })}
          {selectedAdditionals?.map((item, i) => {
            return (
              <FilterItem
                key={`filter-additional-${i}`}
                text={item.text}
                handleRemove={() => {
                  const newState = selectedAdditionals.filter(
                    (attr) => attr.id != item.id
                  )
                  handleAdditionalChange(newState)
                }}
              />
            )
          })}
          {selectedAttributes?.map((item, i) => {
            return (
              <FilterItem
                key={`filter-attribute-${i}`}
                text={item.text}
                handleRemove={() => {
                  const newState = selectedAttributes.filter(
                    (attr) => attr.id != item.id
                  )
                  handleAttributeChange(newState)
                }}
              />
            )
          })}
        </div>
      )
    }
  
    const allFiltersCount =
      (category ? 1 : 0) + filters?.length ||
      0 + selectedAttributes?.length ||
      0 + selectedAdditionals?.length ||
      0
  
    const titlesMap = {
      ondergrond: 'Ondergrond',
      beheer: 'Beheer',
      gebruik: 'Gebruik',
      accommodaties: 'Accommodaties',
      indoor: 'Indoor / Outdoor',
    }
    const orderMap = {
      ondergrond: 5,
      beheer: 8,
      gebruik: 7,
      accommodaties: 2,
      indoor: 6,
    }
  
    return (
      <div className={`bg`}>
        <div className="filterStatus" style={{ order: 0 }}>
          <div className="filterNum">
            <span>{allFiltersCount}</span>
            <h2>Filters</h2>
          </div>
          <div className="editfilter">
            {allFiltersCount > 0 && (
              <ButtonElement variant="empty" handleClick={removeFilters}>
                Verwijder filters
              </ButtonElement>
            )}
            <div className="closeButton">
              <ButtonElement
                variant="empty"
                handleClick={() => setMobileOpen(false)}
              >
                <span style={{ paddingRight: '15px' }}>Sluiten</span>
                <ImageBuilderFrontend
                  image={close}
                  width={20}
                  height={20}
                  alt="close"
                />
              </ButtonElement>
            </div>
          </div>
          {getAllFilters()}
        </div>
  
        {filterSubList?.links && (
          <div className="sidebarBlock" style={{ order: !isLocation ? 0 : 1 }}>
            <FilterLinkList {...filterSubList} />
          </div>
        )}
  
        {mainFilters && (
          <div className="sidebarBlock" style={{ order: !isLocation ? 0 : 3 }}>
            <FilterSubList
              {...mainFilters}
              category={category}
              selectedFilters={filters}
              handleFiltersChange={handleFiltersChange}
              handleFilters={handleFilters}
              resultsPerCategory={resultsPerCategory}
              resultsPerSport={resultsPerSport}
            />
          </div>
        )}
  
        <div className="sidebarBlock" style={{ order: !isLocation ? 0 : 4 }}>
          <FilterDistance
            title="Afstand:"
            addressLabel={''}
            addressId="postalcode"
            addressName="postalcode"
            distanceId="distance"
            distanceName="distance"
          />
        </div>
  
        {additionalFilters &&
          Object.entries(additionalFilters)?.map((item, i) => {
            let order = !isLocation
              ? 0
              : item?.length && item[0] in orderMap
              ? orderMap[item[0]]
              : 9
            return (
              <div
                className="sidebarBlock"
                key={`additional-filterblock-${i}`}
                style={{ order: order }}
              >
                <AttributeFilterList
                  title={titlesMap[item[0]]}
                  attributes={item[1]}
                  handleAttributeChange={handleAdditionalChange}
                  selectedAttributes={selectedAdditionals}
                />
              </div>
            )
          })}
  
        {attributeFilters?.map((attribute, i) => {
          return (
            <div className="sidebarBlock" key={`attribute-filterblock-${i}`}>
              <AttributeFilterList
                {...attribute}
                handleAttributeChange={handleAttributeChange}
                selectedAttributes={selectedAttributes}
              />
            </div>
          )
        })}
      </div>
    )
}
  
export default FilterSidebar