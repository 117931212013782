import React from 'react'
import styled from 'styled-components'
import { secondary_green, white } from '../../colors'

import ListItemElement from '../../elements/listitem'
import ColorBar from '../../elements/colorbars'
import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import { List } from '@mui/material'
import Field from '../../elements/fields'
import { DistanceSearch } from '../distanceSearch'

const Wrapper = styled.div`
  border-bottom: 0;

  background: ${white};
  .pad {
    padding: 33px 31px;
  }
  .subfilters {
    margin-top: 3px;
    padding: 13px 6px;
  }

  @media screen and (max-width: 1280px) {
    .ctype {
      display: none;
    }
  }
`

export const FilterLinkList = ({ title, links = null }) => {
  return (
    <Wrapper>
      <div className="pad">
        <TypographyElement variant="subfilterTitle">{title}</TypographyElement>
        <List>
          {links?.map((filter, index) => {
            return (
              <div key={`filter-item-${filter?.text}-${index}`}>
                <ListItemElement
                  {...filter}
                  href={filter.href || filter.external_url}
                  variant="icon"
                  isfilter="true"
                />
              </div>
            )
          })}
        </List>
      </div>
      <div className="barContainer">
        <ColorBar variant="small1" />
      </div>
    </Wrapper>
  )
}

export const AttributeFilterList = ({
  title,
  attributes,
  handleAttributeChange,
  selectedAttributes,
}) => {
  const handleFilter = (item) => {
    if (selectedAttributes.some((filter) => item.id == filter.id)) {
      const newState = selectedAttributes.filter((sf) => sf.id != item.id)
      handleAttributeChange(newState)
    } else {
      const newState = [...selectedAttributes, item]
      handleAttributeChange(newState)
    }
  }
  return (
    <Wrapper>
      <div className="pad">
        <TypographyElement variant="subfilterTitle">{title}</TypographyElement>
        <List>
          {attributes?.map((attr, index) => {
            const isChecked = selectedAttributes?.some(
              (item) => item.id == attr.id
            )

            return (
              <Field
                key={`attribute-filter-${index}`}
                variant="checkbox"
                name={attr.text}
                id={attr.id}
                handleChange={() => {
                  handleFilter(attr)
                }}
                checkColor={secondary_green}
                checked={isChecked}
                label={attr?.text}
              />
            )
          })}
        </List>
      </div>
    </Wrapper>
  )
}

export const FilterDistance = ({ title = 'Afstand:', addressLabel = '' }) => {
  return (
    <Wrapper>
      <div className="pad">
        <TypographyElement variant="subfilterTitle">{title}</TypographyElement>
        <DistanceSearch
          addressLabel={addressLabel}
          addressId="postalcode"
          addressName="postalcode"
          distanceId="distance"
          distanceName="distance"
        />
      </div>
    </Wrapper>
  )
}

const FilterSublist = ({
  title,
  filters,
  selectedFilters,
  barVariant,
  linkVariant,
  category,
  handleFiltersChange,
  handleFilters,
  resultsPerCategory = {},
  resultsPerSport = {},
}) => {
  // const [selected, setSelected] = React.useState([])
  const [showall, setShowall] = React.useState(false)

  const handleClick = (value) => {
    // const value = event.target.innerText.toLowerCase()
    handleFilters([], value)
  }
  const handleExpand = () => {
    setShowall(!showall)
  }

  const slicedFilters = showall ? filters : filters.slice(0, 6)

  const handleSubfilter = (value) => {
    if (selectedFilters.some((filter) => value.id == filter.id)) {
      const newState = selectedFilters.filter((sf) => sf.id != value.id)
      handleFiltersChange(newState)
    } else {
      const newState = [...selectedFilters, value]
      handleFiltersChange(newState)
    }
  }
  return (
    <Wrapper>
      <div className="pad">
        <TypographyElement variant="subfilterTitle">{title}</TypographyElement>
        <List>
          {slicedFilters?.map((filter, index) => {
            const itemSelected =
              filter?.text?.toLowerCase() == category?.text?.toLowerCase()
            const subfilters = filter?.subfilters || []
            return (
              <div key={`filter-item-${filter?.text}-${index}`}>
                <ListItemElement
                  {...filter}
                  variant={linkVariant}
                  handleClick={() => handleClick(filter)}
                  selected={itemSelected}
                  isfilter="true"
                />
                {itemSelected && (
                  <div className="subfilters">
                    {subfilters?.map((sub, index) => {
                      if (
                        (!(sub.slug in resultsPerSport) ||
                          !resultsPerSport[sub.slug]) &&
                        !selectedFilters.some((item) => item.slug === sub.slug)
                      ) {
                        return null
                      }
                      let subChecked = selectedFilters.some(
                        (sf) => sub.id == sf.id
                      )
                      const subFilterText = `${sub.text} (${
                        resultsPerSport[sub.slug] || 0
                      })`
                      return (
                        <Field
                          key={`subsite-file-${index}`}
                          variant="checkbox"
                          name={sub.text}
                          id={`subfilter_${sub.text}`}
                          handleChange={() => {
                            handleSubfilter(sub)
                          }}
                          checkColor={secondary_green}
                          checked={subChecked}
                          label={subFilterText}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </List>

        {filters && filters.length > 6 && (
          <ButtonElement
            variant="moreFilters"
            expanded={showall}
            handleClick={handleExpand}
          >
            {showall ? 'Toon minder' : 'Toon meer'}{' '}
            <span className="ctype"> Sportcategorieën </span>
          </ButtonElement>
        )}
      </div>
      {barVariant && (
        <div className="barContainer">
          <ColorBar variant={barVariant} />
        </div>
      )}
    </Wrapper>
  )
}

export default FilterSublist
