import moment from 'moment'
import { getMomentTz } from './getMomentTz'
import {
  validateTitleApi,
  validateTitleEventApi,
  validateWebsiteApi,
} from './api/fetchApi'

const validationDict = {
  signup: {
    required: ['name', 'teamname', 'email', 'phone'],
    validateEmail: ['email'],
    validatePhone: ['phone'],
    validateDate: [],
    validateUrl: ['website'],
  },
  addevent: {
    required: [
      'name',
      'description',
      'teamname',
      'type',
      'startdate',
      'enddate',
      'contact_name',
      'contact_email',
      'contact_telephone',
      'sports',
      'header_files',
    ],
    validateEmail: ['email', 'contact_email'],
    validatePhone: ['telephone', 'contact_telephone'],
    validateDate: ['startdate', 'enddate'],
    validateUrl: ['website'],
  },
  addclub: {
    required: [
      'title',
      'description',
      'type',
      'sports',
      'levensfase',
      'logo',
      'header_image',
      'email',
    ],
    validateEmail: ['email'],
    validatePhone: ['telephone'],
    validateDate: [],
    validateUrl: ['website', 'facebook', 'twitter', 'instagram'],
  },
}

export const dateValidation = (start, end) => {
  if (!start || !end) return null
  return getMomentTz(start).isSameOrBefore(getMomentTz(end))
    ? null
    : 'Startdatum mag niet later dan de einddatum zijn'
}

const emailValidation = (value) => {
  // eslint-disable-next-line no-useless-escape
  const isValid =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  return isValid
}

const phoneValidation = (value) => {
  const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/gm
  return value && value.length >= 10 && regex.test(value)
}

const urlValidation = (value) => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i' // validate fragment locator
  )
  return value && regex.test(value)
}

export const clubTitleValidation = (value) => {
  // Returns boolean, based on whther slug is already used or not
  const host = window?.location?.origin
  return validateTitleApi(value, host)
}

export const eventTitleValidation = (title, date) => {
  if (typeof date == 'object') {
    // Format the date
    date = getMomentTz(date).format('DD-MM-YYYY')
  }
  // Returns boolean, based on whther slug is already used or not
  const host = window?.location?.origin
  return validateTitleEventApi(title, date, host)
}

export const urlApiValidation = (value) => {
  // Returns promise with info whether url is valid
  const host = window?.location?.origin
  return validateWebsiteApi(value, host)
}

export const postcodeValidation = (value) => {
  const regex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
  return value && regex.test(value)
}

const dateNotInPast = (value) => {
  const now = getMomentTz().startOf('day')

  let selected
  if (typeof value == 'string') {
    selected = getMomentTz(moment(value, 'DD-MM-YYYY'))
  } else {
    selected = getMomentTz(value)
  }

  return selected.isSameOrAfter(now)
}

export const validation = (value, key, form) => {
  const validateBy = validationDict[form]
  const isRequired = validateBy['required'].includes(key)
  const requiredValid = !isRequired || value
  const notRequiredAndNoValue = !isRequired && !value
  const emailValid =
    !validateBy['validateEmail'].includes(key) ||
    emailValidation(value) ||
    notRequiredAndNoValue
  const phoneValid =
    !validateBy['validatePhone'].includes(key) ||
    phoneValidation(value) ||
    notRequiredAndNoValue
  const dateValid =
    !validateBy['validateDate'].includes(key) ||
    dateNotInPast(value) ||
    notRequiredAndNoValue
  const websiteValid =
    !validateBy['validateUrl'].includes(key) ||
    urlValidation(value) ||
    notRequiredAndNoValue
  
  const error = !requiredValid
    ? 'Dit veld is verplicht'
    : !emailValid
    ? 'Geen geldige email'
    : !phoneValid
    ? 'Geen geldig telefoonnummer'
    : !dateValid
    ? 'Geselecteerde datum mag niet in het verleden liggen'
    : !websiteValid
    ? 'Geen geldige url, voer de volledige url in'
    : null
  return error
}

export const filesizesValidation = (files) => {
  let totalSize = 0
  files.forEach((file) => {
    totalSize = totalSize + file.filesize
  })

  const error =
    totalSize > 10000000
      ? `De maximale uploadgrootte is overschreden (${
          totalSize / 1000000
        }MB / 10MB)`
      : null
  return error
}
